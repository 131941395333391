import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react'

const AppContext = React.createContext();

const ContextProvider = ({ children }) => {

  const [darkMode, setDarkMode] = useState(false);
  const contextValue = useMemo(() => ({ darkMode, setDarkMode }), [darkMode, setDarkMode]);
  const [loadedMode, setLoadedMode] = useState(false);
  useLayoutEffect(() => {
    if (!loadedMode) {
      setLoadedMode(true);
      setDarkMode(localStorage.getItem('dark_mode_active') === 'true');
    }
  }, [loadedMode]);
  useEffect(() => {
    localStorage.setItem('dark_mode_active', !!darkMode);
  }, [darkMode]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  )
}

export default AppContext;

export { ContextProvider };